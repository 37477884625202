import Rotas from  './rotas';

import "./App.css"


function App() {

  return (
    
      <Rotas />
  
  );

}

export default App;